/* eslint-disable no-undef */
// Only one item MUST have the "default: true" key

module.exports = {
  tr: {
    default: true,
    path: `tr`,
    locale: `tr-TR`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `tr`,
    ogLanguage: `tr_TR`,
    defaultTitle: `Türkçe başlık`,
    defaultDescription: `Türkçe açıklama metni`,
  },
  en: {
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Using i18n with Gatsby`,
    defaultDescription: `Gatsby example site using MDX and dependency-free i18n`,
  },
}
