import { faEnvelope, faGlobe } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Dropdown from "react-bootstrap/Dropdown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LocaleContext from "../../localeContext"
import LocalizedLink from "components/localized-link"
import React from "react"
import Row from "react-bootstrap/Row"
import { navigate } from "gatsby"
import styles from "./index.module.scss"
import svgMaestro from "images/maestro-logo.svg"
import svgMasterCard from "images/mastercard-logo.svg"
import svgVisa from "images/visa-logo.svg"
import useTranslations from "components/use-translations"

export default function Footer() {
  const { locale } = React.useContext(LocaleContext)
  const {
    genel: {
      hakkimizda,
      iletisim,
      programMerkezi,
      servishaneTeknikServisProgrami,
      blog,
      hesapBilgileri,
      kullanimKosullari,
      gizlilikPolitikasi,
      mesafeliSatisSozlesmesi,
      iadeveTeslimatSartlari,
      lisansSatinAl,
      dahaFazla,
      yasal,
      yazilim,
      cekilisProgrami,
      kodvizitTarafindanHazirlandi,
      anasayfa,
      kutuphaneProgrami,
      cekilisSitesi,
      indirmeMerkezi,
    },
  } = useTranslations()

  const changeLanguage = targetLang => {
    const { origin, pathname } = window.location
    if (targetLang === "tr" && pathname[3] === "/") {
      navigate(origin + pathname.substr(3))
    } else {
      navigate(
        origin + "/" + targetLang + pathname.substr(pathname[3] === "/" ? 3 : 0)
      )
    }
  }
  return (
    <footer
      className={`border-top bg-white ${styles.footer}`}
      style={{ fontSize: "15px" }}
    >
      <Container
        className=" d-md-flex  w-100 mt-1 m-auto"
        style={{ fontSize: 15, lineHeight: "18px" }}
      >
        <div className="pt-5 w-100">
          <Row>
            <Col lg="3" className="mb-3 mb-lg-0">
              <ul className="list-unstyled">
                <li>
                  <strong>Kodvizit</strong>
                </li>
                <li>
                  <LocalizedLink to="/">{anasayfa}</LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/hakkimizda/">{hakkimizda}</LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/blog/">{blog}</LocalizedLink>
                </li>
              </ul>
            </Col>
            <Col lg="3" className="mb-3 mb-lg-0">
              <ul className="list-unstyled">
                <li>
                  <strong>{yazilim}</strong>
                </li>
                <li>
                  <LocalizedLink to="/magaza/">{lisansSatinAl}</LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/kutuphane-programi/">
                    {kutuphaneProgrami}
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/cekilis-sitesi/">
                    {cekilisSitesi}
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/cekilis-programi/">
                    {cekilisProgrami}
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/servishane/">
                    {servishaneTeknikServisProgrami}
                  </LocalizedLink>
                </li>
              </ul>
            </Col>
            <Col lg="3" className="mb-3 mb-lg  -0">
              <ul className="list-unstyled">
                <li>
                  <strong>{yasal}</strong>
                </li>
                <li>
                  <LocalizedLink to="/kullanim-kosullari/">
                    {kullanimKosullari}
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/gizlilik-politikasi/">
                    {gizlilikPolitikasi}
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/mesafeli-satis-sozlesmesi/">
                    {mesafeliSatisSozlesmesi}
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/iade-teslimat-sartlari/">
                    {iadeveTeslimatSartlari}
                  </LocalizedLink>
                </li>
              </ul>
            </Col>
            <Col lg="3">
              <ul className="list-unstyled">
                <li>
                  <strong>{dahaFazla}</strong>
                </li>
                <li>
                  <LocalizedLink to="/iletisim/">{iletisim}</LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/hesap-bilgileri/">
                    {hesapBilgileri}
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/programlar/">
                    {programMerkezi}
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/indirme/">{indirmeMerkezi}</LocalizedLink>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
      <Container className="d-block d-lg-flex w-100 mt-5 mb-5 text-muted text-lg-left text-center">
        <div className="my-auto ">
          {kodvizitTarafindanHazirlandi} Copyright © 2017 - 2024
          <br />
          <div className="justify-content-center justify-content-lg-start mt-2 align-items-center d-flex flex-row flex-nowrap">
            <img src={svgVisa} alt="visa logo" height="22" />
            <img src={svgMasterCard} alt="mastercard logo" height="30" />
            <img src={svgMaestro} alt="maestro logo" height="30" />
          </div>
        </div>
        <div className="ml-auto">
          <Dropdown className=" mx-auto my-3 btn-lg  m-lg-0 d-block d-lg-inline-block">
            <Dropdown.Toggle variant="light" size="sm">
              <FontAwesomeIcon icon={faGlobe} />{" "}
              {locale === "en" ? "English" : "Türkçe"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {locale !== "tr" && (
                <Dropdown.Item
                  onClick={event => {
                    event.preventDefault()
                    changeLanguage("tr")
                  }}
                >
                  Türkçe&apos;ye Geç
                </Dropdown.Item>
              )}
              {locale !== "en" && (
                <Dropdown.Item
                  onClick={event => {
                    event.preventDefault()
                    changeLanguage("en")
                  }}
                >
                  Switch to English
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>{" "}
          {/*d-block d-lg-inline-block eklenecek dil seçenekleri eklenince*/}
          <a
            aria-label="Facebook"
            className="my-auto text-center ml-lg-3 mr-2 text-muted"
            href="https://www.facebook.com/kodvizit/"
          >
            <FontAwesomeIcon size="lg" icon={faFacebookF} />{" "}
          </a>
          <a
            aria-label="Twitter"
            className="my-auto mr-1 text-muted"
            href="https://www.twitter.com/kodvizit/"
          >
            <FontAwesomeIcon
              className="my-auto mx-2"
              size="lg"
              icon={faTwitter}
            />{" "}
          </a>
          <a
            aria-label="Instagram"
            className="my-auto mr-1 text-muted"
            href="https://www.instagram.com/kodvizit/"
          >
            <FontAwesomeIcon
              className="my-auto mx-2"
              size="lg"
              icon={faInstagram}
            />{" "}
          </a>
          <a
            aria-label="Mail"
            className="my-auto mr-1 text-muted"
            href="mailto:bilgi@kodvizit.com"
          >
            <FontAwesomeIcon
              className="my-auto mx-2"
              size="lg"
              icon={faEnvelope}
            />{" "}
          </a>
        </div>
      </Container>
    </footer>
  )
}
